const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://uoutnncaub.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://oy3u82ney1.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.11.2',
    HOSTNAME: 'https://teams-service.staging.debmarine.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://2m8olo71sa.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;
